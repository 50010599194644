import React, { useContext } from 'react';
import styled from '@emotion/styled';
import Logo from './Logo';
import Auth from '../Auth/Auth';
import { mq } from '../../utils/mediaQuery';
import { breakpoints, color, gradient } from '../../utils/style';
import IcomoonIcon from '../shared/IcomoonIcon';
import dictionary from '../../utils/dictionary';
import { StoreContext } from '../../store';

// Images
import phone from '../../img/tel.svg';
import money from '../../img/money.svg';
import userlogin from '../../img/user-login.svg';
import promo from '../../img/promo.svg';

const MainWrapper = styled.div`
  width: 100%;
  height: 100px;
  justify-content: space-between;
  position: relative;

  ${mq('md')} {
    position: relative;
    padding-top: 20px;
  }

  @media (max-width: ${breakpoints.md}) {
    padding-top: 0;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Contact = styled.div`
  display: flex;
  margin-right: 16px;
  background: rgb(33, 30, 151);
  background: ${gradient.purpleBunting};
  border-radius: 14px;
  border: none;
  padding: 0;
  text-align: center;
  align-items: center;
  height: 44px;
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  img {
    margin: 0 12px 0 0;
  }

  ${mq('md')} {
    width: 34px;
    height: 34px;
    padding: 0;

    img {
      width: 24px;
      height: 24px;
      left: 4px;
      top: 4px;
      position: absolute;
    }
  }
`;

const Phone = styled(Contact)`
  width: 168px;
  margin-right: 16px;
  user-select: none;
  cursor: pointer;
  justify-content: center;
  transition: all .2s linear;

  ${mq('md')} {
    background-color: ${color.darkPurple};
  }
  :active,
  :hover {
    background-color: ${color.jacksonsPurple};
    border: none;
    background-image: none;
  }

  @media (max-width: ${breakpoints.md}) {
    & span {
      display: none;
    }
  }
`;

const Lnwasia = styled(Contact)`
  width: 178px;
  margin-right: 16px;
  user-select: none;
  color: ${color.lightGreen};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s linear;
  svg {
    margin: 0 12px 0 0;
  }

  ${mq('md')} {
    background-color: ${color.darkPurple};
  }
  :active,
  :hover {
    background-color: ${color.jacksonsPurple};
    border: none;
    background-image: none;
  }
`;

const InlineWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 30px;

  ${mq('md')} {
    right: 0;
    top: 20px;
    width: auto;

    div {
      width: 32px;
      height: 32px;
      padding: 0;
      position: relative;

      &:first-of-type {
        margin-right: 6px;
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        width: 24px;
        height: 24px;
        margin: 0;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const ContactText = styled.div`
  ${mq('md')} {
    display: none;
  }
`;

const MobileLink = styled.a`
  width: 35px;

  & > svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

export default () => {
  const [state] = useContext(StoreContext);
  const {
    user: { userName },
  } = state;

  return (
    <MainWrapper>
      <MobileLink href="/promotion/">
        <img className="iconImage" src={promo} alt="promo" />
      </MobileLink>
      <Logo to="/" styleProps="" text={process.env.SITE_NAME_SHORT} />
      {userName ? (
        <MobileLink href="/banking/">
          <img className="iconImage" src={money} alt="money" />
        </MobileLink>
      ) : (
        <MobileLink href="/sign-up-now/">
          <img className="iconImage" src={userlogin} alt="money" />
        </MobileLink>
      )}
      <InlineWrap
        styleProps={{
          height: '100px',
          padding: '30px 0',
        }}
      >
        <Phone onClick={() => window.open(dictionary.phoneLink, '_blank')} elType="phone">
          <img src={phone} alt="phone" />
          <span>{dictionary.phoneNumber}</span>
        </Phone>
        <Lnwasia onClick={() => window.open(dictionary.lineLink, '_blank')}>
          <IcomoonIcon className="icon" color="#FFF" icon="line-id" size={25} />
          <ContactText>{dictionary.lineWithAtSign}</ContactText>
        </Lnwasia>
      </InlineWrap>
      <Auth hideRegButton />
    </MainWrapper>
  );
};
