import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
  border,
  color,
  flex,
  formsAutoFillingStyles,
  formStyles,
  sizing,
  SocialIconWrapper,
} from '../../../utils/style';
import { invokePopup, closePopup } from '../../shared/PopupWindow';
import { StoreContext } from '../../../store';
import IcomoonIcon from '../../shared/IcomoonIcon';
import { freespinLogin } from '../../../utils/api/freespin';
import { setToken, setUserEmail, setUserName } from '../../../utils/api';
import { setUser } from '../../../store/actions';
import dictionary from '../../../utils/dictionary';
import { isValidField } from '../../../utils/system';
import Modal from '../../shared/Modal';

const Wrapper = styled('div')`
  ${formStyles};
  ${formsAutoFillingStyles};
  position: relative;
  padding: 0 0 20px;
  margin-top: -10px;
  text-align: left;
  .soc-btns {
    display: flex;
    justify-content: center;
    align-items: center;

    .soc-button {
      border-radius: 50%;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      padding: 0;
      svg {
        margin-left: 0;
      }
    }
  }

  & * {
    outline: none;
  }

  .loading {
    position: relative;
  }
  .loading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${color.black};
    opacity: 0.7;
    z-index: 1;
  }
  .two-fields {
    .fieldWrap {
      width: 48%;
      display: inline-block;
      vertical-align: top;
    }
  }
  .red-button {
    float: right;
  }

  .button {
    margin-right: 0;
    svg {
      margin-left: 5px;
    }
    &:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  .inputName {
    margin-bottom: 5px;
  }
  .forgot-password {
    font-size: ${sizing.small};
    justify-content: center;
    display: flex;
    padding-top: 29px;
    color: rgba(255, 255, 255, 0.6);
  }
`;

const MsgWrap = styled('div')`
  margin: 10px 0 30px;
  span {
    display: block;
    background-color: ${color.yellow};
    color: ${color.black};
    padding: 5px 10px;
    text-align: center;
  }
`;

const BtnWrapper = styled('div')`
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  .white-button {
    margin-right: 0;
  }
`;

const hideAllErrors = () => {
  const errorBoxes = document.getElementsByClassName('errorMsg');
  if (errorBoxes.length) {
    Object.values(errorBoxes)
      .forEach((element) => {
        element.classList.remove('visible');
      });
  }
};

const LoginFormOpen = (event, popupId, fn = () => false) => {
  invokePopup(event, popupId);
  hideAllErrors();
};

const initialValues = {
  username: '',
  password: '',
};

const RegisterBtnComponent = styled.div`
  display: flex;
  margin-right: 16px;
  border: 1px solid #eee;
  padding: 8px 12px;
  border-radius: ${border.borderRadius14};
`;

const RegisterButton = () => (
  <RegisterBtnComponent>
    <Link to="/sign-up-now" className="register-btn">
      สมัครสมาชิก
    </Link>
  </RegisterBtnComponent>
);

const Button = styled('button')`
  padding: 0 !important;
  display: inline-flex !important;
  width: 100px;
  background: rgba(0, 0, 0, 0) !important;
  border: 1px solid ${color.darkPink} !important;
  color: ${color.red} !important;

  :hover,
  :active {
    color: red !important;
    opacity: 0.7 !important;
  }

  span {
    padding: 10px 20px;
    font-size: 14px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
  }
`;

const WrapButtons = styled.div`
  display: flex;
  height: 40px;

  & > div,
  & > button {
    padding: 10px;
    ${flex};
    font-size: 12px;

    & > span {
      padding: 0;

      ${flex};
    }
  }
`;

// REFACTOR
// Problem not in this component. We use formik and Fields can get components as a prop so our
// Solution with every time write tone of code - bad practice.
// Decompose Formik!
const FormBody = () => {
  const [state, dispatch] = useContext(StoreContext);
  const [validating, setValidating] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const onSubmit = (values, { resetForm }) => {
    freespinLogin(values.username, values.password)
      .then((json) => {
        if (!json || !json.token) {
          setError(dictionary.formLoginOrPasswordIncorrect);
          return false;
        }

        setToken(json.token);
        setUserName(json.user_nicename);
        setUserEmail(json.user_email);
        setUser(dispatch, json.user_nicename);
        setError('');
        setSuccess(dictionary.formSentSuccessfully);
        resetForm({});

        closePopup(false, 'login-popup-window', 'login-popup-window-id');
        closePopup(false, 'bottom-login-popup', 'bottom-login-popup-id');
      });
  };
  const submitButton = (e) => setValidating(true);

  return (
    <Wrapper>
      <MsgWrap id="loginMsg">
        {error && (
          <span className="errorMsg" id="loginErrorMsg">
            {error}
          </span>
        )}
        {success && (
          <span className="successMsg" id="successSuccessMsg">
            {success}
          </span>
        )}
      </MsgWrap>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object()
          .shape({
            username: Yup.string()
              .required('จำเป็นต้องใช้'),
            password: Yup.string()
              .required('จำเป็นต้องใช้'),
          })}
        render={({ errors, status, touched, isValid, values }) => (
          <Form id="loginForm">
            <div className="fieldWrap iconField">
              <Field
                type="text"
                name="username"
                placeholder="ยูสเซอร์เนม"
                id="login-username"
                value={values.username || ''}
              />
              {isValidField('username', errors, touched, validating) && (
                <div className="error">! {isValidField('username', errors, touched, validating)}</div>
              )}
            </div>
            <div className="fieldWrap iconField">
              <Field
                type="password"
                name="password"
                placeholder="รหัสผ่าน"
                id="login-password"
                value={values.password || ''}
              />
              {isValidField('password', errors, touched, validating) && (
                <div className="error">! {isValidField('password', errors, touched, validating)}</div>
              )}
            </div>
            <BtnWrapper>
              <div className="soc-btns">
                <SocialIconWrapper href={process.env.FACEBOOK_LINK} style={{ marginRight: 0 }}>
                  <IcomoonIcon color={color.lightningYellow} icon="facebook" size={40} />
                </SocialIconWrapper>
              </div>
              <WrapButtons className="wrap-btns">
                <RegisterButton />
                <Button
                  onClick={submitButton}
                  aria-label="เข้าสู่ระบบ"
                  className="button green-button"
                  type="submit"
                  disabled={!isValid && validating}
                >
                  <span>เข้าสู่ระบบ</span>
                </Button>
              </WrapButtons>
              {status && status.msg && <div>{status.msg}</div>}
            </BtnWrapper>
            <a className="forgot-password" target="_blank" rel="noopener noreferrer" href="/contact-us/">
              ลืมรหัสผ่าน ติดต่อ Call Center
            </a>
          </Form>
        )}
      />
    </Wrapper>
  );
};

const LoginForm = ({ popupId, modalExtraClass = '' }) => (
  <Modal title="เข้าสู่ระบบ" popupId={popupId} modalExtraClass={modalExtraClass}>
    <FormBody popupId={popupId} />
  </Modal>
);

export default LoginForm;
export { LoginFormOpen };
