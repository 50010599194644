import React, { useContext } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import HomePageContext from '../shared/Context/HomePageContext';
import { mq, spreadProps } from '../../utils/mediaQuery';
import LogoIcon from '../../icons/svg/LogoIcon';
import { breakpoints } from '../../utils/style';

const LogoLink = styled('div')`
  position: absolute;
  left: 40%;
  right: 0;
  top: 30px;
  z-index: 1;
  width: 25%;

  ${mq('sm')} {
    left: 20%;
    top: 15px;
    right: 30%;
    width: 50%;
  }
  ${(props) => spreadProps(props.styleProps)};

  @media (max-width: ${breakpoints.md}) {
    width: 156px;
    position: relative;
    left: unset;
    top: unset;
    right: unset;

    & svg {
      width: 100%!important;
    }
  }
`;

const Logo = ({ to, styleProps }) => {
  const homeValue = useContext(HomePageContext);
  const logoClick = () => () => {
    if (homeValue && homeValue.setMode) {
      homeValue.setMode('home');
    }
  };

  return (
    <LogoLink styleProps={styleProps}>
      <Link to={to} onClick={logoClick}>
        <LogoIcon />
      </Link>
    </LogoLink>
  );
};

Logo.propTypes = {
  to: PropTypes.string.isRequired,
  styleProps: PropTypes.string.isRequired,
};

export default Logo;
