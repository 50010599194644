import React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { mq, spreadProps } from '../../utils/mediaQuery';
import { color, breakpoints, gradient } from '../../utils/style';

const RegisterBtn = styled.div`
  height: 44px;
  width: 115px;
  border: none;
  text-transform: capitalize;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  padding: 10px 0;
  border-radius: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  position: relative;
  color: ${color.rose};
  background: ${color.rose};
  background: ${gradient.roseBittersweet};

  ${mq('md')} {
    width: calc(50% - 16px);
    margin-left: 8px;
  }

  &:before {
    content: '';
    display: flex;
    position: absolute;
    background-color: ${color.blueZodiac};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    margin: 1px;
    border-radius: 14px;
  }

  &:active,
  &:hover {
    background: ${color.rose};
    background: ${gradient.roseBittersweet};

    & > span {
      color: white;
    }
  }

  &:hover:before {
    background-color: transparent;
  }

  & > span {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) => spreadProps(props.styleProps)};

  @media (max-width: ${breakpoints.md}) {
    padding: 0 0;
  }
`;

const RegisterButton = ({ className }) => (
  <RegisterBtn className={className} onClick={() => navigate('/sign-up-now')} text="สมัครสมาชิก">
    <span>สมัครสมาชิก</span>
  </RegisterBtn>
);

export default RegisterButton;
